<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0" :span="24" class="organizetoolbar" style="padding-bottom: 0px">
        <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
          <!--<el-form-item label="岗位名称:">
            <el-input v-model="selectForm.Positions" placeholder="岗位名称"></el-input>
          </el-form-item>-->
          <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <el-table :data="users" highlight-current-row height="100%" @current-change="selectCurrentRow" @row-dblclick="handleShow" class="new_table">
        <!-- <el-table-column type="index" width="50"> </el-table-column> -->
        <el-table-column prop="REV_PRO_STATUS" label="状态"> </el-table-column>
        <el-table-column prop="REV_CREATER_DATE" label="发布时间"> </el-table-column>
        <el-table-column prop="Positions" show-overflow-tooltip label="岗位名称"> </el-table-column>
        <el-table-column prop="Number" show-overflow-tooltip label="招聘数量"> </el-table-column>
        <el-table-column prop="AstrictEdu" label="学历要求"></el-table-column>
        <el-table-column prop="AstrictAge" label="年龄要求"></el-table-column>
        <el-table-column prop="AstrictSpecialty" show-overflow-tooltip label="专业要求"> </el-table-column>
        <el-table-column prop="AstrictIndustryExp" show-overflow-tooltip label="从业经验"></el-table-column>
        <el-table-column prop="WorkSalaryStar" label="薪资待遇"></el-table-column>
        <el-table-column prop="WorkSalaryEnd" label="薪资待遇"></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper,total" :page-count="total" :total="totaldata">
    </el-pagination>


    <!-- 弹窗 -->
    <el-dialog :title="operation ? '新增' : '查看'" :visible.sync="SakaryVisible" v-model="SakaryVisible" :close-on-click-modal="false">
        <el-form :rules="SakaryRules" ref="SakaryForm" :model="SakaryForm" label-width="100px">
            <el-row>
                <el-col :span="12">
                  <el-form-item label="招聘岗位:" prop="Positions">
                    <el-input type="text" :disabled="true" v-model="SakaryForm.Positions">
                      <el-button slot="append" icon="el-icon-search" @click="chooseUser"></el-button>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="招聘人数:" prop="Number">
                        <el-input type="text" v-model="SakaryForm.Number" :disabled="disabled"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="display:none;">
                <el-col :span="12">
                    <el-form-item label="岗位ID:" prop="PositionsId">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.PositionsId"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="岗位说明书ID:" prop="PostExplainId">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.PostExplainId"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="薪资待遇:" prop="WorkSalaryStar">
                        <el-input type="text" v-model="SakaryForm.WorkSalaryStar" :disabled="disabled">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="至:" prop="WorkSalaryEnd">
                        <el-input type="text" v-model="SakaryForm.WorkSalaryEnd" :disabled="disabled"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="公司:" prop="Company">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.Company"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="是否一线员工:" prop="IsYxJob">
                      <el-input type="text" :disabled="true" v-model="SakaryForm.IsYxJob"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="年龄要求:" prop="AgeRequest">
                        <el-input type="text" v-model="SakaryForm.AgeRequest" :disabled="disabled"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="学历要求:" prop="Schoolwork">
                        <el-input type="text" v-model="SakaryForm.Schoolwork" :disabled="disabled"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                
                <el-col :span="12">
                    <el-form-item label="专业要求:" prop="Education">
                        <el-input type="text" v-model="SakaryForm.Education" :disabled="disabled"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="margin-bottom:10px;">
                <el-col :span="24">
                    <label for="Summarize" class="el-form-item__label">从业经验:</label>
                    <el-input type="textarea" v-model="SakaryForm.Experience" style="width: 90%" :disabled="disabled"></el-input>
                </el-col>
            </el-row>
            <el-row style="margin-bottom:10px;">
                <el-col :span="24">
                    <label for="Summarize" class="el-form-item__label">岗位描述:</label>
                    <el-input type="textarea" v-model="SakaryForm.JobDescription" style="width: 90%" :disabled="disabled"></el-input>
                </el-col>
            </el-row>
            <el-row style="margin-bottom:10px;">
                <el-col :span="24">
                    <el-form-item label="申请原因:" prop="ApplyReason">
                        <el-input type="textarea" v-model="SakaryForm.ApplyReason" :disabled="disabled"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="margin-bottom:14px;">
                <el-col :span="24">
                    <el-button type="primary" v-on:click="onPostExplain()">岗位说明书</el-button>
                </el-col>
            </el-row>
            <el-row v-if="operation1">
                <el-col :span="24" style="text-align: center">
                    <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
    <el-dialog :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
      <UserChoosejob :data="choosedusers" :all="true" :single="false" @callback="usercallFunction"></UserChoosejob>
    </el-dialog>

    <postExplainModal ref="postExplainModal" @callback="getData"></postExplainModal>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UploadFiles from "../../components/UploadFiles";
import {
  AddSalaRecruiting,
  UserInfoGetInfoSalaRecruiting,
  GetCertificateDetail,
  GetPostExplainInfoList,
  GetDepartmentOrg,
  GetExpectationInfo,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import UserChoose from "../../components/UserChoose";
import Toolbar from "../../components/newToolbar";
import UserChoosejob from "../../components/UserChoosejob";
import DptTool from "../../components/Org/DptTool"; // 选取部门控件
import postExplainModal from "../componentModals/PostExplainModalNew"    // 岗位职责
export default {
  components: { Toolbar, UserChoose, UploadFiles,UserChoosejob,DptTool,postExplainModal },
  data() {
    return {
      disabled:false,
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      operation: false, // true:新增, false:编辑
      operation1: true,
      pageSize: 20, // 每页的数据条数
      page: 1,
      searchVal: "",
      CertCategoryLists: [],
      CertProfessionLists: [],
      SavePlaceLists: [],
      currentRow: {}, // 选中行
      SakaryVisible: false,
      choosedusers: [],
      selectForm: {
        Positions: null
      },
      addUserVisible: false,
      SakaryForm: {
        PostLevel:"",//岗位级别
        WorkSalaryStar:"",//薪资待遇
        WorkSalaryEnd:"",//至薪资待遇
        IsYxJob:"",//是否一线
        TeacherName:"",//导师姓名（选择一线时需填写导师）
        Positions:"",//招聘岗位
        Number:"",//招聘数量
        Education:"",//专业
        Experience:"",//工作经验
        SexRequest:"",//性别要求
        AgeRequest:"",//年龄要求
        DemandCategory:"",//需求类别
        JobDescription:"",//岗位描述
        DiathesisRequest:"",//素质要求
        ApplyReason:"",//申请原因、备注
        Schoolwork:"",//学历要求
        PositionsId:"",//岗位ID
        PostExplainId:"",//岗位职责id
        Company:"",//公司

      },
      users: [],
      buttonList: [],
      SakaryRules: {
        Positions: [
          { required: true, message: "请输入招聘岗位", trigger: "blur" }],
        Number: [
          { required: true, message: "请输入招聘人数", trigger: "blur" },
        ],
        WorkSalaryStar: [
          { required: true, message: "请输入薪资待遇", trigger: "blur" },
        ],
        WorkSalaryEnd: [
          { required: true, message: "请输入薪资待遇至", trigger: "blur" },
        ],
        IsYxJob: [
          { required: true, message: "请选择是否一线员工", trigger: "blur" },
        ],
        AgeRequest:[
          {required:true,message:"请填写年龄要求",trigger:"blur"}
        ],
        Schoolwork:[
          {required:true,message:"请填写学历要求",trigger:"blur"}
        ],
        Education:[
          {required:true,message:"请填写专业要求",trigger:"blur"}
        ],
        Company:[
          {required:true,message:"请填写公司",trigger:"blur"}
        ],
        ApplyReason:[
          {required:true,message:"请填写申请原因",trigger:"blur"}
        ],
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    usercallFunction(newdata) {
      console.log(newdata);
        //获取公司
        GetDepartmentOrg({ deptId: newdata.DepartmentId }).then((res) => {
          if (res.data.code == 200) {
            console.log(res.data.data);
            this.SakaryForm.Company = res.data.data.deptName;
            if (res.data.data.deptName != null) {
                this.SakaryForm.Positions = newdata.Name;
                this.SakaryForm.PositionsId = newdata.Id;
                if(newdata.workTypeName=="后勤"){
                  this.SakaryForm.IsYxJob = "否";
                }else{
                  this.SakaryForm.IsYxJob = "是";
                }
                
            } else {
              this.SakaryForm.Company = "";
              }
          } else {
            this.$message({
              message: "获取部门信息出错",
              type: "error",
              duration: 5000,
              });
          }
        });
        //岗位说明书
        GetPostExplainInfoList({ postId: newdata.Id }).then((res) => {
          if (res.data.status == 200) {
            console.log( res.data.response.data[0]);
            if (res.data.response.data[0].Id != null) {//id
                this.SakaryForm.PostExplainId = res.data.response.data[0].Id;
            } else {
              this.SakaryForm.PostExplainId = "";
            }
            if (res.data.response.data[0].AstrictAge != null) {//年龄
                this.SakaryForm.AgeRequest = res.data.response.data[0].AstrictAge;
            } else {
              this.SakaryForm.AgeRequest = "";
            }
            if (res.data.response.data[0].AstrictEdu != null) {//学历
                this.SakaryForm.Schoolwork = res.data.response.data[0].AstrictEdu;
            } else {
              this.SakaryForm.Schoolwork = "";
            }
            if (res.data.response.data[0].AstrictSpecialty != null) {//专业
                this.SakaryForm.Education = res.data.response.data[0].AstrictSpecialty;
            } else {
              this.SakaryForm.Education = "";
            }
            if (res.data.response.data[0].ExpRequire != null) {//从业经验
                this.SakaryForm.Experience = res.data.response.data[0].ExpRequire;
            } else {
              this.SakaryForm.Experience = "";
            }
            if (res.data.response.data[0].PostSummarize != null) {//岗位概述
                this.SakaryForm.JobDescription = res.data.response.data[0].PostSummarize;
            } else {
              this.SakaryForm.JobDescription = "";
            }
            // if (res.data.response.data[0].Factory != null) {//公司
            //     this.SakaryForm.Company = res.data.response.data[0].Company;
            // } else {
            //   this.SakaryForm.Company = "";
            // }
            
          } else {
            this.$message({
              message: "获取岗位职责信息出错",
              type: "error",
              duration: 5000,
            });
          }
        });
      this.addUserVisible = false;
    },
    onPostExplain(){//岗位说明书
      if (this.SakaryForm.PostExplainId != null) {
        this.$refs.postExplainModal.operation = false; // 添加
        this.$refs.postExplainModal.optType = false;   // 编辑
        // this.$refs.postExplainModal.onShowDlg(this.SakaryForm.PostExplainId);
        this.$refs.postExplainModal.onShowDlg(this.SakaryForm.PositionsId);
      }else{
        this.$refs.postExplainModal.operation = true; // 添加
        this.$refs.postExplainModal.optType = true;   // 编辑
        this.$refs.postExplainModal.onShowDlg();
      }
      
    },
    chooseUser() {
      this.addUserVisible = true;
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row.REV_CID)
      
      GetExpectationInfo({ recruitingId: row.REV_CID }).then((res) => {
        console.log(res.data)
        this.SakaryForm = res.data.response;
        this.SakaryVisible = true;
      });
      this.operation = true;
      this.operation1 = true;
    },
    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      GetExpectationInfo({ recruitingId: row.REV_CID }).then((res) => {
        this.SakaryForm = res.data.response;
        this.SakaryVisible = true;
      });
      this.disabled=true,
      this.operation = false;
      this.operation1 = false;
    },
    handleAdd() {
      (this.SakaryForm = {
        PostLevel:"",//岗位级别
        WorkSalaryStar:"",//薪资待遇
        WorkSalaryEnd:"",//至薪资待遇
        IsYxJob:"",//是否一线
        TeacherName:"",//导师姓名（选择一线时需填写导师）
        Positions:"",//招聘岗位
        Number:"",//招聘数量
        Education:"",//专业
        Experience:"",//工作经验
        SexRequest:"",//性别要求
        AgeRequest:"",//年龄要求
        DemandCategory:"",//需求类别
        JobDescription:"",//岗位描述
        DiathesisRequest:"",//素质要求
        ApplyReason:"",//申请原因、备注
        Schoolwork:"",//学历要求
        PositionsId:"",//岗位ID
        PostExplainId:"",//id
        Company:"",//公司
      }),
      this.disabled=false;
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    //获取用户列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }

      let para = {
        page: this.page,
        size: this.pageSize,
        Positions: this.selectForm.Positions,
      };
      UserInfoGetInfoSalaRecruiting(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    SavePlaceModel(id) {
      let obj = {};
      obj = this.SavePlaceLists.find((item) => {
        //model就是上面的数据源

        return item.Name === id; //筛选出匹配数据
      });
      this.SakaryForm.SavePlaceId = obj.Id;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            console.log(para);
            if (this.operation) {
              //新增
              AddSalaRecruiting(para).then((res) => {
                console.log(res.data.success)
                if (res.data.success) {
                  this.$message({
                    message: "成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    // message: "失败",
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            } else {
              //修改
              // UpdateCertificate(para).then((res) => {
              //   if (res.data.success) {
              //     this.$message({
              //       message: "修改成功",
              //       type: "success",
              //       duration: 5000,
              //     });
              //     this.SakaryVisible = false;
              //     this.getData(false);
              //   } else {
              //     this.$message({
              //       message: "修改失败",
              //       type: "error",
              //       duration: 5000,
              //     });
              //   }
              // });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    
  },

  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.calc-height {
  height: calc(100vh - 300px) !important;
}
</style>

